import React from "react";
import { Link as LinkRouter } from "react-router-dom";

// @ts-ignore
import styles from "../styles/WindowServices.module.scss";


export default function WindowServices() {
  return (
    <div id="services-div">
    </div>
  );
}
